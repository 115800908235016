import { notification } from 'antd';
import { useCallback } from 'react';

import { useHistory } from 'src/core/navigation/history';
import { useApi } from 'src/core/network/api';

import * as P from 'src/app/main/app.path';

export default () => {
    const { post, loading } = useApi('/login');
    const history = useHistory();

    const onFinish = useCallback(
        (values) => {
            post(values).then(({ payload, error }) => {
                if (error) {
                    notification.error({
                        message: error.message,
                    });
                } else if (payload.hasChallenge && payload.type === 'NEW_PASSWORD_REQUIRED') {
                    notification.info({
                        message: 'For your security, please change your password',
                    });

                    history.push(P.RENEW, {
                        state: {
                            username: values.email,
                            session: payload.session,
                        },
                    });
                } else {
                    history.push(
                        P.NAVIGATE,
                        {
                            state: {
                                isAuthenticated: true,
                                accessToken: payload.access.token,
                                expiredAt: payload.access.expires,
                            },
                        },
                        {
                            withSearch: true,
                        }
                    );
                }
            });
        },
        [post, history]
    );

    return {
        onFinish,
        isLoading: loading,
    };
};
