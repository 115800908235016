import { useCallback, useEffect } from 'react';

import { useHistory } from 'src/core/navigation/history';
import { useApi } from 'src/core/network/api';

import * as P from 'src/app/main/app.path';

export default () => {
    const { post, loading } = useApi('/renew');
    const { push, location, replace } = useHistory<{ username: string; session: string }>();

    useEffect(() => {
        if (!location.state.username || !location.state.session) {
            replace(P.ROOT);
        }
    });

    const onFinish = useCallback(
        (values) => {
            post({
                ...values,
                email: location.state.username,
                session: location.state.session,
            }).then(({ payload }) => {
                push(
                    P.NAVIGATE,
                    {
                        state: {
                            isAuthenticated: true,
                            accessToken: payload.access.token,
                            expiredAt: payload.access.expires,
                        },
                    },
                    {
                        withSearch: true,
                    }
                );
            });
        },
        [post, location.state.username, location.state.session, push]
    );

    return {
        initialValues: { email: location.state.username },
        isLoading: loading,
        onFinish,
    };
};
