import { useEffect } from 'react';

import { useHistory } from 'src/core/navigation/history';

import * as P from 'src/app/main/app.path';

export default () => {
    const { replace } = useHistory<{
        isAuthenticated: boolean;
        accessToken: string;
        expiredAt: string;
    }>();

    useEffect(() => {
        replace(
            P.LOGIN,
            {},
            {
                withSearch: true,
            }
        );
    });

    return {};
};
