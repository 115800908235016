import React from 'react';

import { PageHeader, Typography } from 'antd';

import usePresenter from './validate.presenter';

const Navigate = () => {
    usePresenter();
    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <div style={{ width: '100%' }}>
                <PageHeader title={<Typography.Title level={1}>Navigate</Typography.Title>} />
            </div>
        </div>
    );
};

export default Navigate;
