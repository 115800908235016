import { useEffect } from 'react';

import { useConfig } from 'src/core/common/config';
import { useHistory } from 'src/core/navigation/history';

import * as P from 'src/app/main/app.path';

export default () => {
    const env = useConfig();
    const { search, href, location, replace } = useHistory<{
        isAuthenticated: boolean;
        accessToken: string;
        expiredAt: string;
    }>();

    useEffect(() => {
        if (location.state.isAuthenticated) {
            const domain = search.continue ? (search.continue as string) : env.DEFAULT_CONTINUE;
            href(domain, {
                accessToken: location.state.accessToken,
                expiredAt: location.state.expiredAt,
            });
        } else {
            replace(P.ROOT);
        }
    });

    return {};
};
