import React from 'react';

import { ConfigContext } from 'src/core/common/config';
import { ApiContext } from 'src/core/network/api';
import Router from 'src/core/navigation/router';

import Layout from './app.layout';
import Route from './app.route';

export default ({ config }: { config: any }) => (
    <ConfigContext.Provider value={config}>
        <ApiContext.Provider value={{ url: config.API_URL }}>
            <Router>
                <Layout content={<Route />} />
            </Router>
        </ApiContext.Provider>
    </ConfigContext.Provider>
);
