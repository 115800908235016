import React from 'react';

import { Route, Switch } from 'src/core/navigation/route';

import Login from 'src/app/page/login/login.page';
import Renew from 'src/app/page/renew/renew.page';
import Navigate from 'src/app/page/navigate/navigate.page';
import Validate from 'src/app/page/validate/validate.page';

import * as P from './app.path';

export default () => (
    <Switch>
        <Route exact={true} path={P.RENEW} component={Renew} />
        <Route exact={true} path={P.LOGIN} component={Login} />
        <Route exact={true} path={P.NAVIGATE} component={Navigate} />
        <Route component={Validate} />
    </Switch>
);
