import React from 'react';
import ReactDOM from 'react-dom';

import { loadEnv } from 'src/core/common/isomorph';

import App from './app/main/app.module';

import './index.css';
import * as serviceWorker from './serviceWorker';

const LOCAL_ENV = {
    API_URL: process.env.REACT_APP_API_URL,
    DEFAULT_CONTINUE: process.env.REACT_APP_DEFAULT_CONTINUE,
    ACCESS_TOKEN_KEY: 'accessToken',
    EXPIRED_AT_KEY: 'expiredAt',
    REDIRECT_KEY: 'continue',
};

const main = async () => {
    const config =
        process.env.NODE_ENV === 'production'
            ? { ...LOCAL_ENV, ...(await loadEnv('/.poorman-known/env.js')) }
            : LOCAL_ENV;

    ReactDOM.render(
        <React.StrictMode>
            <App config={config} />
        </React.StrictMode>,
        document.getElementById('root')
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
};

main();
