import React from 'react';

import { Layout } from 'antd';

import { ReactComponent as Logo } from './app.logo.svg';

const App: React.FC<{ content: any }> = ({ content }) => (
    <Layout style={{ minHeight: '100vh' }}>
        <Layout.Sider width='40%'>
            <div
                style={{ display: 'flex', alignItems: 'center', height: '100%', padding: '0 24px' }}
            >
                <div>
                    <Logo width={undefined} style={{ marginTop: '-8px', height: '110px' }} />
                    <p style={{ color: '#fff', fontSize: '24px' }}>Your solution provider</p>
                </div>
            </div>
        </Layout.Sider>
        <Layout>
            <Layout.Content style={{ background: '#fff' }}>{content}</Layout.Content>
        </Layout>
    </Layout>
);

export default App;
