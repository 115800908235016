import React from 'react';

import { Button, Form, Input, PageHeader, Typography } from 'antd';

import usePresenter from './renew.presenter';

const Renew = () => {
    const formLayout = {
        wrapperCol: { span: 16 },
    };
    const { initialValues, isLoading, onFinish } = usePresenter();
    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <div style={{ width: '100%' }}>
                <PageHeader title={<Typography.Title level={1}>Renew Password</Typography.Title>} />
                <div style={{ padding: '24px' }}>
                    <Form
                        {...formLayout}
                        name='basic'
                        initialValues={initialValues}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name='email'
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input placeholder='Email' allowClear={true} disabled={true} />
                        </Form.Item>

                        <Form.Item
                            name='password'
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password
                                placeholder='Password'
                                allowClear={true}
                                disabled={isLoading}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                disabled={isLoading}
                                loading={isLoading}
                            >
                                Renew
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Renew;
