import React from 'react';

import { Button, Checkbox, Form, Input, PageHeader, Typography } from 'antd';

import usePresenter from './login.presenter';

const Login = () => {
    const { isLoading, onFinish } = usePresenter();

    const formLayout = {
        wrapperCol: { span: 16 },
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <div style={{ width: '100%' }}>
                <PageHeader title={<Typography.Title level={1}>Login</Typography.Title>} />
                <div style={{ padding: '24px' }}>
                    <Form {...formLayout} name='basic' onFinish={onFinish}>
                        <Form.Item
                            name='email'
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input placeholder='Email' allowClear={true} disabled={isLoading} />
                        </Form.Item>

                        <Form.Item
                            name='password'
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password
                                placeholder='Password'
                                allowClear={true}
                                disabled={isLoading}
                            />
                        </Form.Item>

                        <Form.Item name='remember' valuePropName='checked'>
                            <Checkbox disabled={isLoading}>Remember me</Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                disabled={isLoading}
                                loading={isLoading}
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Login;
